import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
  ActivatedRoute,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TokenService } from './account/token.service';
import { UserService } from './account/user.service';
import { AppInitializerService } from './app-initializer.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggedGuard implements CanActivate {
  constructor(
    private router: Router,
    private api: AppInitializerService,
    private token: TokenService,
    private users: UserService,
    private route: ActivatedRoute
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const hasToken = !!this.token.get();

    if (hasToken && next.routeConfig.path === 'connection') {
      return of(this.router.parseUrl('/chat'));
    } else if (next.routeConfig.path === 'completeProfile') {
      console.log(next.queryParams.operationId, environment.dvp4m);
      if (next.queryParams.operationId && environment.dvp4m) {
        return of(true);
      } else {
        return this.users.checkRegistration(next.queryParams).pipe(
          map(({ redirectPage, user, msisdn, signupKey }) => {
            // console.log('logged guard');
            // user = {
            //   freeWoman: null,
            //   msisdn: null,
            //   redirect: null,
            //   redirectPage: null,
            //   signupKey: 'QXE1dndTQ1c2a1ZiYXpicHRHMUF8MTU5MzA4ODM4MQ==',
            //   user: null,
            // };
            // user = {
            //   id: 3933142,
            //   nickname: null,
            //   email: 'Bryandu144@gmail.com',
            //   phone: null,
            // };
            // redirectPage = null;
            switch (redirectPage) {
              case 'external':
                window.open(this.api.params.landingPageLink.href, '_self');
                return false;
              case 'connexion':
                return this.router.parseUrl(`/connection?login=${user.email || user.phone}`);
              default:
                this.users.msisdn = msisdn;
                this.users.signupKey = signupKey;
                this.users.allowSubscribeAccess = true;
                return true;
            }
            // return true;
          }),
          catchError(() => of(false))
        );
      }
    }
    return of(true);
  }
  canActivateChild(): Observable<boolean | UrlTree> {
    const hasToken = !!this.token.get();

    if (!hasToken) {
      return of(this.router.parseUrl('/connection'));
    }

    return of(true);
  }

  checkUser() {}
}
