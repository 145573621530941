import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { trackPageView } from '@snowplow/browser-tracker';
import { Angulartics2 } from 'angulartics2';
import { TokenService } from 'src/app/account/token.service';
import { UserService } from 'src/app/account/user.service';
import { WsService } from 'src/app/account/ws.service';
import { AppInitializerService } from 'src/app/app-initializer.service';
import { Gender } from 'src/app/shared/enums/gender.enum';
import { LookingFor } from 'src/app/shared/enums/looking-for.enum';
import { City } from 'src/app/shared/interfaces/city';
import { User } from 'src/app/shared/interfaces/user';
import { AgePipe } from 'src/app/shared/pipe/age.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  readonly agePipe = new AgePipe();
  readonly Gender = Gender;
  readonly LookingFor = LookingFor;
  wsSubscriber = null;
  showWaiting = false;
  showWaitingError = false;
  LP;
  public mask = [
    /[\d\+\(]/,
    /[\d\s\(\)\+]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
    /[\d\s\(\)]/,
  ];

  country = this.api.params.extCode;
  hide = true;
  phoneDisable = false;
  user: User = {
    id: null,
    nickname: null,
    password: '',
    birthdate: this.agePipe.birthdateFromAge(35),
    gender: Gender.man,
    date_online: new Date(),
    city: this.getCity(),
    description: null,
    email: null,
    phone: null,
    under_your_blacklist: false,
    search: null,
    looking_for: 2,
    avatar: null,
    avatars: [],
    logged: false,
    is_animator: false,
    ga_details: null,
    sb_token: null,
    is_moderator: false,
    hobbies: null,
    operator: null,
  };
  min = 4;
  max = 25;
  form: FormGroup;
  constructor(
    private users: UserService,
    private tracker: Angulartics2,
    private formBuilder: FormBuilder,
    private token: TokenService,
    private router: Router,
    private api: AppInitializerService,
    private ws: WsService,
    private route: ActivatedRoute
  ) {
    this.LP = api.params.landingPageLink.href;
    this.form = formBuilder.group({
      password: formBuilder.control(null, [Validators.required, Validators.minLength(4)]),
    });
    // trackPageView();
  }

  ngOnInit() {
    // this.showWaiting = environment.dvp4m;
    console.log(
      this.users.allowSubscribeAccess,
      environment.dvp4m && !this.users.allowSubscribeAccess
    );
    if (environment.dvp4m && !this.users.allowSubscribeAccess) {
      console.log('here');
      this.getUserDVP();
    }
    this.users.getMsisdn().subscribe((num) => {
      const login = num || '';
      this.users.showSubscribing(true);
      const isMsisdn = environment.completeProfileInputs.includes('phoneNumber');
      const isEmail = environment.completeProfileInputs.includes('email');
      if (isMsisdn) {
        this.user.phone = login;
        this.form.addControl(
          'phone',
          this.formBuilder.control({ value: login, disabled: !!login }, [
            Validators.required,
            Validators.minLength(8),
          ])
        );
      }
      if (isEmail) {
        this.form.addControl('email', this.formBuilder.control(null, [Validators.required]));
      }
    });
  }
  getUserDVP() {
    this.showWaiting = true;
    this.showWaitingError = false;
    this.ws.connect();
    setTimeout(() => {
      this.ws.check(this.route.snapshot.queryParams.operationId, this.api.params.country);
      this.wsSubscriber = this.ws.message.subscribe((msg) => {
        if (msg.body.exist !== 'False') {
          console.log('checking exist', msg.body.exist);
          this.wsSubscriber.unsubscribe();
          this.users
            .checkRegistration(this.route.snapshot.queryParams)
            .subscribe(({ redirectPage, user, msisdn, signupKey }) => {
              this.showWaiting = false;

              switch (redirectPage) {
                case 'external':
                  window.open(this.api.params.landingPageLink.href, '_self');
                  break;
                case 'connexion':
                  this.router.navigate([`/connection`], {
                    queryParams: { login: user.email || user.phone },
                  });
                  break;
                default:
                  this.user.phone = msisdn;
                  this.form.get('phone').setValue(msisdn);
                  this.users.signupKey = signupKey;
                  this.users.allowSubscribeAccess = true;
              }
            });
        }
        if (msg.body.count >= 15) {
          this.wsSubscriber.unsubscribe();
          this.showWaitingError = true;
        }
      });
    }, 1000);
  }
  getCity(): City {
    this.users.getServiceCapitale().subscribe((data) => {
      if (data && data.id) {
        return (this.user.city = data);
      }
    });
    return null;
  }
  setGender(gender) {
    this.user.gender = gender;
  }
  onSubmit() {
    this.user.password = this.form.value.password;
    this.user.email = this.form.value.email;
    this.user.phone = this.form.value.phone || this.user.phone;
    this.users.registerUser(this.user).subscribe(
      (data: { userauth: string; user: User }) => {
        this.token.set(data.userauth);
        this.router.navigate(['/chat/']);
        this.tracker.eventTrack.next({
          action: '/vpv/subscription_1step_validate_registration_V3',
          properties: {
            category: 'Registered',
          },
        });
      },
      (err) => {
        switch (err.error_code) {
          case 109:
            this.tracker.eventTrack.next({
              action: '/vpv/subscription_1step_incorrect_email_V3',
              properties: {
                category: 'Email',
              },
            });
            break;
          case 110:
            this.tracker.eventTrack.next({
              action: '/vpv/subscription_1step_incorrect_phone_V3',
              properties: {
                category: 'Phone',
              },
            });
            break;
          case 111:
            this.tracker.eventTrack.next({
              action: '/vpv/subscription_1step_incorrect_password_V3',
              properties: {
                category: 'Password',
              },
            });
            break;
        }
      }
    );
    // this.registerNewUser();
  }
  sendEmailTracker(event: Event): void {
    const pseudo = event.target as HTMLInputElement;
    if (pseudo.value.length === 1) {
      this.tracker.eventTrack.next({
        action: '/vpv/subscription_1step_clic_email_box_V3',
        properties: {
          category: 'Email',
        },
      });
    }
  }

  sendPasswordTracker(event: Event): void {
    const password = event.target as HTMLInputElement;
    if (password.value.length === 1) {
      this.tracker.eventTrack.next({
        action: '/vpv/subscription_1step_clic_password_box_V3',
        properties: {
          category: 'Password',
        },
      });
    }
  }
}
